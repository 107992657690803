.pricing {
    display: flex;
    justify-content: space-between;
}

.package {
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: lightgray;
    width: 30%;
    text-align: center;
    cursor: pointer;
}

.package h6 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background-color: #edebeb;
}

.package p {
    background-color: #edebeb;
    margin-bottom: 0px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 5px;
}

.package .Additional {
    background-color: lightgray !important;
    margin-bottom: 0px;
    padding-bottom: 10px;
    text-align: center;
    padding-top: 20px;
}

.package.selected {
    border: 1px solid green !important;
    border-radius: 5px;
}

.package .selectedClass {
    background-color: green !important;
    color: white;
}

.package .hiddenClass {
    display: none;
}


@media only screen and (min-width: 420px) and (max-width: 480px) {
    .Additional {
        font-size: 14px !important;
    }
}

/* Font size 12px for screen widths between 370px and 420px */
@media only screen and (max-width: 420px) {
    .Additional {
        font-size: 12px !important;
    }
}