.playbutton {
    height: 100px !important;
    width: 100px !important;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -55%);
}

.buttonText {
    left: 43%;
    position: absolute;
    top: 55%;
    font-weight: 700;
    font-size: large;

}

@media (max-width: 530px) and (min-width: 450px) {
    /* CSS styles go here */

    .buttonText {
        left: 41%;
        top: 50%;
    }

}

@media (max-width: 530px) and (min-width: 360px) {

    /* CSS styles go here */
    .playbutton {
        height: 100px !important;
        width: 100px !important;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -55%);
    }


}

@media (max-width: 450px) and (min-width: 360px) {
    /* CSS styles go here */

    .buttonText {
        left: 39%;
        top: 50%;
    }

}