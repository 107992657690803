video {
  object-fit: cover;
  margin: 0px;
  width: 100%;
}

.titleStyle {
  color: white;
  margin-top: 1rem;
  /* Corresponding to mt-3 */
  text-align: center;
}

.bgStyle {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  /* This keeps the background image fixed */
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  overflow-y: auto;
}

/* ... (previous styles) ... */
#print {
  display: none !important;
}

.react-tooltip {
  overflow: visible;
  z-index: 10000000;
}

.containerBox {
  width: 80%;
  height: 120px;
  min-height: 120px;
  background-color: #F2F2F2;
  margin: 0 auto;
  /* Center the div horizontally */
  display: flex;
  font-family: inherit;
  position: relative;
}

.playButton {
  width: 50px;
  /* Adjust as per your desired size */
  height: 50px;
  margin-left: 1rem;
  /* You can use an icon here */
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-right: 20px;
  /* Space between the button and the heading */
}

.topicContent {
  border: 1px solid #9e9e9e;
  padding: 5px;

}

.topicContent.active {
  background-color: lightgray;
}

.topicsList {
  font-family: 'Roboto';
  border: 2px none gray;
  max-height: 423px;
  min-height: 423px;
  height: 500px;
  background-color: white;
}

.headingStyles {
  border-bottom: 1px solid lightgray;
  margin-bottom: 0px;
}

.marginTopModules {
  margin-top: 12.8px;
}

.topicsListUnder {
  font-family: 'Roboto';
  border: 2px none gray;
  padding: 1rem;
  padding-top: 1.5rem;
  max-height: 423px;
  min-height: 423px;
  height: 500px;
  overflow-y: auto;
  border-radius: 2px;
  background-color: #fffafa;
}

.headingSection {
  margin-left: 2rem;
  font-family: inherit;
}

.paraGraphText {
  font-size: 14px;
}

.bottomText {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  font-size: 12px;
  font-family: inherit;
  /* Optional padding for aesthetics */
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #a39c9c99 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-Quiz {
  width: 70%;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  min-height: 270px;
  border: 3px solid green;
}

.close-button {
  /* Style your close button */
  float: right;
  background: none;
  border: none;
  width: 26px;
  font-weight: 900;
}

.start-button {
  background-color: green;
  color: white;
  border-radius: 3px;
  border: none;
  padding: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  width: 130px;
  height: 43px;
}

.start-button-quiz {
  background-color: green;
  color: white;
  border-radius: 3px;
  border: none;
  padding: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.start-button:disabled {
  background-color: lightgray;
}

.start-button-div {
  text-align: center;
  margin-top: 50px;
}

.answer-description {
  font-size: 14px;
  font-family: 'Roboto';
}

.error-message {
  color: red;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

/* Individual tab items */
.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

/* Active tab */
.tab-item.active {
  border-bottom-color: transparent;
  background-color: #ddd;
}

/* Tab content area */
.tab-content {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  max-height: 500px;
  overflow: auto;
}

.tab-content p {
  margin: 0pt !important;
}

.buttonRedirect {
  color: white;
  background: rgb(73, 179, 73);
  border: 1px solid green;
}

.containerSection {
  display: flex;
  align-items: flex-start;
  padding: 100px;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-left: 100px;
  width: 40%;
}

.details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px;
  width: 60%;
}

.imgBanner {
  max-width: 100%;
  width: 100%;
  height: 80%;
}

.download-button {
  margin-top: 10px;
  background-color: rgb(77, 131, 20);
  color: white;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  border: none;
  outline: none;
  height: 50px;
  width: -webkit-fill-available;
}

.heading1 {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.details-container p,
li {
  font-size: 18px;
  line-height: 2;
}